@media (hover:hover) {
    .headerLink:hover p {
        color: #0D0D93
    }

    .headerLink:hover svg {
        fill: #0D0D93
    }

    .headerLink:hover hr {
        color: #0D0D93
    }
}