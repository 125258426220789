@import './tailwind.css';
@import './fonts.css';

body {
  margin: 0;
  font-family: 'Pretendard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Pretendard', 'Courier New',
    monospace;
}