.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
    background-color: white !important;
    border-radius: 0.25rem;
    opacity: 1;
    transition: all ease 0.2s;
}

.swiper-pagination-bullet-active {
    width: 2rem
}

.swiper-pagination {
    bottom: 20% !important
}

@media (max-width: 768px) {
    .swiper-pagination {
        bottom: 10% !important;
    }
}